import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useParentsList(root) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)
  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'name', sortable: true },
    { key: 'email', label: 'Email', sortable: true },
    { key: 'children', label: 'children', sortable: true },
    { key: 'current_balance', label: 'balance', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const defaultPerPage = Number(localStorage.getItem('parentsPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const filterDataStatus = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const clearFilters = () => {
    filterDataStatus.value = ''
  }

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch(programId, () => {
    refetchData()
  })

  watch([currentPage, perPage, searchQuery, filterDataStatus],
    ([currentPageVal, perPageVal, searchQueryVal, filterDataStatusVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, filterDataStatusPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-parents', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-parents', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-parents', 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterDataStatusVal !== filterDataStatusPrevVal) {
        pushStateFiltersParams(root, 'admin-parents', 'filterDataStatus', filterDataStatusVal)
      }
    })

  const fetchParentList = (ctx, callback) => {
    const query = { ...root.$route.query }
    const payloadData = {
      perPage: perPage.value,
      page: query.currentPage || 1,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      programId: programId.value,
    }
    if (searchQuery.value) {
      payloadData.search = searchQuery.value
    }
    if (filterDataStatus.value) {
      payloadData.status = filterDataStatus.value
    }
    store
      .dispatch('app-parents/fetchParentList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching parents' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const updateParent = queryParams => axios
    .put(`/auth/parents/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Parent was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating parent',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const exportParents = queryParams => axios
    .get('/auth/parents/export/csv', { params: queryParams })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting users',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'verified') return 'success'
    if (status === 'rejected') return 'danger'
    if (status === 'unverified') return 'info'
    return 'primary'
  }

  const actionOptions = [
    { value: 'edit', title: 'Edit', link: 'admin-parent-profile' },
  ]

  const loadScript = url => new Promise(resolve => {
    const script = document.createElement('script')
    script.setAttribute('src', url)
    script.async = true

    script.onload = () => {
      /* emit to global event bus to inform other components
        * we are already loading the script */
      resolve()
    }

    document.head.appendChild(script)
  })

  const createAndMountFormElements = () => {
    const elements = this.stripe.elements()

    this.cardNumberElement = elements.create('cardNumber')
    this.cardNumberElement.mount('#card-number-element')

    this.cardExpiryElement = elements.create('cardExpiry')
    this.cardExpiryElement.mount('#card-expiry-element')

    this.cardCvcElement = elements.create('cardCvc')
    this.cardCvcElement.mount('#card-cvc-element')

    this.cardNumberElement.on('change', this.setValidationError)
    this.cardExpiryElement.on('change', this.setValidationError)
    this.cardCVCElement.on('change', this.setValidationError)
  }

  const setValidationError = event => {
    this.stripeValidationError = event.error ? event.error.message : ''
  }

  return {
    fetchParentList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    filterDataStatus,
    sortBy,
    totalItems,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,
    actionOptions,

    loadScript,

    resolveStatusVariant,
    createAndMountFormElements,
    setValidationError,

    refetchData,

    updateParent,
    clearFilters,
    exportParents,
  }
}
